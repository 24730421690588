import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { CssBaseline, GlobalStyles } from '@mui/material';
import './global.css';
//import Snowfall from 'react-snowfall'


// https://demo.templatemonster.com/pt-br/demo/297700.html?_gl=1*yv8ogf*_gcl_aw*R0NMLjE3MjYwMjM3NzguQ2p3S0NBanczUC0yQmhBRUVpd0EzeVBod0lsYmIwSV9FcU0zM1V6N1lLWktqOGJJWkhnY1NkUExBUFZ5SVltQkZwXzRoSEVmNFdjUFZ4b0NjcHNRQXZEX0J3RQ..*_gcl_au*MjEwMDIwNjE1OS4xNzI2MDIzNzQw*_ga*MTc3NjgzMjAxNi4xNzI2MDIzNzM1*_ga_FTPYEGT5LY*MTcyNjAyMzczNS4xLjEuMTcyNjAyNDIwNi4xNi4wLjA.
const globalStyles = (
  <GlobalStyles
    styles={{
      body: {
        backgroundColor: '#121215',
        color: '#fff', // Isso é opcional, caso queira que o texto seja branco por padrão
        margin: 0,
        padding: 0,
        minHeight: '100vh', // Garante que cubra toda a tela
      },
      '#root': {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }
    }}
  />
);

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    {globalStyles}
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);









// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';
// import { CssBaseline, GlobalStyles } from '@mui/material';
// import Snowfall from 'react-snowfall';
// import './global.css';

// const globalStyles = (
//   <GlobalStyles
//     styles={{
//       body: {
//         backgroundColor: '#121215',
//         color: '#fff',
//         margin: 0,
//         padding: 0,
//         minHeight: '100vh',
//       },
//       '#root': {
//         minHeight: '100vh',
//         display: 'flex',
//         flexDirection: 'column',
//       },
//     }}
//   />
// );

// ReactDOM.render(
//   <React.StrictMode>
//     <CssBaseline />
//     {globalStyles}
//     <div
//       style={{
//         position: 'fixed',
//         top: 0,
//         left: 0,
//         width: '100vw',
//         height: '100vh',
//         pointerEvents: 'none', // Garante interação com o conteúdo abaixo
//         zIndex: 9999, // Mantém a neve acima de todo o conteúdo
//       }}
//     >
//       <Snowfall
//         color="#ffffff" // Cor dos flocos de neve
//         snowflakeCount={60} // Quantidade total de flocos
//         speed={[1.0, 1.0]} // Velocidade mínima e máxima dos flocos
//         wind={[-0.5, 2.0]} // Direção horizontal mínima e máxima (vento)
//         radius={[1.0, 4.0]} // Tamanho mínimo e máximo dos flocos em pixels
//         rotationSpeed={[-1.0, 1.0]} // Velocidade de rotação mínima e máxima dos flocos (graus por frame)
//         changeFrequency={150} // Frequência de alteração do vento e velocidade (em frames)
//         opacity={[0.5, 1]} // Opacidade mínima e máxima dos flocos
//         images={undefined} // Substitua por um array de imagens se quiser usar flocos personalizados
//         style={{
//           position: 'absolute', // Para garantir que fique na posição correta
//           top: 0,
//           left: 0,
//           width: '100%',
//           height: '100%',
//         }}
//       />
//     </div>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );